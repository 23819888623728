import React from 'react'
import Tags from '../tags/Tags'
import Archives from '../archives/Archives'
import Style from './index.module.scss'
import MarkdownStyle from '../markdownContent/index.module.scss'
import { withPrefix } from 'gatsby'

const Content = (
  <>
    <h2>Profile</h2>
    <h4>社名</h4>
    <ul>
      <li>メイキットシステム</li>
    </ul>
    <h4>名前</h4>
    <ul>
      <li>Kuriyama</li>
    </ul>
    <h4>主な制作物</h4>
    <ul>
      <li>NodeJS, C#, PHP, Go, Java, RubyによるWebアプリケーション</li>
      <li>Kotlin, JavaによるAndroidアプリケーション</li>
      <li>Swift, Objective-CによるiOSアプリケーション</li>
    </ul>
    <h4>使用言語</h4>
    <ul>
      <li>
        JavaScript, TypeScript, Kotlin, Swift, Go, Ruby Java C#, Objective-C PHP
      </li>
    </ul>
    <h2>ポートフォリオ</h2>
    <div>
      <h3>レシピノート - 画像でレシピを簡単管理</h3>
      <div style={{ textAlign: 'center', margin: '32px' }}>
        <img
          src={`${withPrefix('/')}img/recipe-note-icon.png`}
          alt="icon"
          width="200px"
        />
      </div>
      <div style={{ textAlign: 'center' }}>
        <a
          href="https://play.google.com/store/apps/details?id=xyz.makitsystem.recipe_note&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          target="_blank"
        >
          <img
            alt="Google Play で手に入れよう"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/ja_badge_web_generic.png"
            width="256px"
          />
        </a>
      </div>
      <h4 style={{ marginTop: '32px', marginBottom: '16px' }}>
        ■ レシピの一括管理ができるアプリ
      </h4>
      <ul>
        <li>
          レシピサイト、レシピ本、手書きのノートなど、散らばったレシピを画像で一括管理！
        </li>
        <li>レシピを画像で保存するから、入力の手間はかからない！</li>
        <li>タグを駆使して自分だけのオリジナルレシピブックを作成しよう！</li>
      </ul>
      <div className={Style.portfolioRecipeNoteImgParent}>
        <img
          src={`${withPrefix('/')}img/recipe-note-screen-shot1.png`}
          alt="レシピノートスクリーンショット1"
        />
        <img
          src={`${withPrefix('/')}img/recipe-note-screen-shot2.png`}
          alt="レシピノートスクリーンショット2"
        />
        <img
          src={`${withPrefix('/')}img/recipe-note-screen-shot3.png`}
          alt="レシピノートスクリーンショット3"
        />
        <img
          src={`${withPrefix('/')}img/recipe-note-screen-shot4.png`}
          alt="レシピノートスクリーンショット4"
        />
      </div>
    </div>
    <h2>Contact</h2>
    <div>
      <p>仕事の依頼、相談</p>
      <p>お問い合わせをいつでもお待ちしています</p>
      <p>お気軽にご連絡下さい</p>
    </div>
    <div>
      <table>
        <tbody>
          <tr>
            <td>gmail</td>
            <td>
              <a href="mailto:kuriyamacreate@gmail.com">
                kuriyamacreate@gmail.com
              </a>
            </td>
          </tr>
          <tr>
            <td>github</td>
            <td>
              <a href="https://github.com/kurisankaku" target="_blank">
                https://github.com/kurisankaku
              </a>
            </td>
          </tr>
          <tr>
            <td>twitter</td>
            <td>
              <a href="https://twitter.com/kurisankaku" target="_blank">
                https://twitter.com/kurisankaku
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </>
)

const Layout = ({ archiveList, tagItems }) => (
  <div className={Style.layoutRoot}>
    <div className={Style.layoutContent}>
      <div className={MarkdownStyle.markdownBody}>{Content}</div>
    </div>
    <div className={Style.layoutMenu}>
      <div>
        <Tags items={tagItems} />
      </div>
      <div className={Style.layoutArchive}>
        <Archives list={archiveList} />
      </div>
    </div>
  </div>
)

export default Layout
