import React from 'react'
import { Helmet } from 'react-helmet'
import About from '../components/about'
import { useFirebase } from '../components/firebase/FirebaseContext'
import ArchivesData from '../staticdata/ArchivesData'
import TagsData from '../staticdata/TagsData'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'

const Page = () => {
  useFirebase(firebase => {
    firebase.analytics().setCurrentScreen(window.location.pathname)
  }, [])

  return (
    <>
      <Helmet>
        <title>About</title>
        <meta name="description" content="事業所情報。RecipeNoteをリリースしています。便利なサービスを作成中です。" />
      </Helmet>
      <Header activeType={0} />
      <About tagItems={TagsData()} archiveList={ArchivesData()} />
      <Footer />
    </>
  )
}

export default Page
